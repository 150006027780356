import React from "react";
import AboutImage from '../assets/images/about-julia-image.jpg';

class About extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            image: "https://julia-cosmetics.de/wp-content/uploads/2018/02/julia-huettmann-portrait-2.jpg"
        }
    }
    render() { 
        return (
            <section id="about" className={'section'}>
                <div className="container">
                    <div className="about-container centered">
                        <div className={'about-gallery'}>
                            <img className={'img-responsive'} src={AboutImage} alt="Julia Hüttmann Portrait"/>
                        </div>
                        <div className={'about-text rose-background'}>
                            <div className={'text-wrapper'}>
                                <h2 className={'section-title'}>Ihre<br/>zertifizierte<br/><strong>Pigmentiererin</strong></h2>
                                <p className={'text-justify'}>Hallo, ihr Lieben,</p>
                                <p className={'justify-center'}>Mein Name ist Julia und ich bin zertifizierte Pigmentiererin im Bereich Microblading und Permanent Make-up.</p>
                                <p className={'justify-content'}>In meinem Studio berate ich dich gerne welche Behandlung zu dir passt und was möglich ist.</p>
                                <p className={'justify-content'}>Kundenzufriedenheit steht bei mir an erster Stelle.</p>
                                <p className={'justify-content'}>Meine Kunden sollen sich bei mir bei einer Behandlung gut aufgehoben fühlen. Es erfordert Fachkenntnisse und Erfahrung seitens des Behandlers um natürliche Ergebnisse und eine sichere Anwendungen zu gewährleisten. Ich werde dich umfassend beraten, auf deine Wünsche eingehen und eine angenehme, hygienische Umgebung bieten.</p>
                                <p className={'justify-content'}>Bist du auch noch unsicher und hast Fragen, die für dich vorher aufgeklärt werden müssen. Dann schreib mir gerne eine Nachricht über den Whatsapp-Kontaktbutton.</p>
                                <p className={'justify-content'}>Ich berate dich sehr gern und wir werden die passende Technik, Form und Farbe für dich und deinen Typ-Mensch finden.</p>
                                <p className={'justify-content'}>Ich freu mich auf dich, Julia.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
 
export default About;