import React from "react";

class PermanentMakeUp extends React.Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    render() { 
        return (            
            <section id="content" className={"section"}>
                <div className={"container"}>
                    <h2>Was ist Permanent Make-Up?</h2>
                    <p>Beim Permanent Make-Up werden Farbpigmente in die oberste Hautschicht gestochen. Die Technik ist vergleichbar mit dem Tätowieren, nur wird nicht ganz so tief gestochen: Mit einer Maschine wird die Farbe in die Haut eingebracht. Deshalb hält die Farbe nicht auf Dauer, sondern nur zwei bis fünf Jahre. Besonders häufig wird Permanent Make-Up eingesetzt, um Konturen im Gesicht zu betonen. So kann der Lidstrich oder die Wimpernkranzverdichtung gezogen werden, die Augenbrauen werden mit feinen Linien nachverdichtet oder mit einem Strich zusätzlich betont. Auch die Lippen lassen sich mit einer gut gezogenen Kontur hervorheben und mit Farbpigmenten schattieren.</p>
                    <h2>Die Vorteile von Permanent Make-Up</h2>
                    <p>Permanent Make-up verläuft und verschmiert nicht. Es sitzt 24 Stunden am Tag perfekt, ob direkt beim Aufwachen, nach durchgetanzten Nächten, nach 3 Stunden Sport.</p>
                    <h2>Das muss man bei Permanent Make-Up beachten</h2>
                    <p>Permanent Make-up sollte sehr gut überlegt sein, schließlich handelt es sich um eine Art Tätowierung, die lange Zeit sichtbar sein wird. -Ihr solltet euch immer an eine zertifizierte Person wenden. Achtet darauf, dass die Nacharbeit im Preis inbegriffen ist. Die Vorzeichnung muss von euch vor dem Eingriff bestätigt werden.</p>
                    <h2>Wie funktioniert Permanent Make-Up?</h2>
                    <p>Die Kosmetikerin zeichnet vor dem Eingriff mit einem Stift die Kontur des Permanent Make-Ups auf, bevor die Pigmente mit einer extrem dünnen Nadel in die Haut gestochen werden. Die Behandlung dauert ca. 1-2 Stunden. Direkt nach der Behandlung muss mit Hautrötungen und leichten Schwellungen gerechnet werden.</p>
                    <h2>Wie lange hält Permanent Make-Up?</h2>
                    <p>Permanent Make-up bedeutet nicht zwangsläufig, dass es für immer bleibt. In der Regel hält Permanent Make-Up je nach Methode 2 bis 5 Jahre. Im Laufe der Zeit werden die künstlich eingeführten Farbpigmente durch die natürliche Hauterneuerung eliminiert, sodass die Farbe nach und nach verblasst. Wie lange das Permanent Make-Ups hält, hängt von verschiedenen Faktoren ab: Davon, wie schnell sich die Haut selbst erneuert, von den verwendeten Farben, vom Lebensstil (z.B. Sonneneinstrahlung, Peeling, häufiges Schwitzen, verwendeter ursprünglicher Farbton).</p>
                </div>
            </section>
        );
    }
}
 
export default PermanentMakeUp;