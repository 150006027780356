import React from "react";
import Masonry from 'react-masonry-component';

class Pricing extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pricing: [
                {
                    name: "Augenbrauen",
                    childs: [
                        {
                            key: "Microblading",
                            value: "290,00€"
                        },
                        {
                            key: "Microshading / Kombibrows",
                            value: "290,00€"
                        },
                        {
                            key: "Powder Brows",
                            value: "290,00€"
                        },
                        {
                            key: "Klassisches Permanent Make-Up",
                            value: "250,00€"
                        },
                        {
                            key: "Erstbehandlung + EINE Nacharbeit enthalten",
                            value: ""
                        },
                        {
                            key: "Auffrischen Microblading",
                            value: ""
                        },
                        {
                            key: "Nach 1 Jahr",
                            value: "120,00€"
                        },
                        {
                            key: "Nach 1,5 Jahren",
                            value: "150,00€"
                        },
                        {
                            key: "Jede weitere Behandlung",
                            value: "50,00€"
                        },
                        {
                            key: "Auffrischen Powderbrows, Ombrébrows, Microshading",
                            value: ""
                        },
                        {
                            key: "Nach 1 Jahr",
                            value: "150,00€"
                        },
                        {
                            key: "Nach 1,5 Jahren",
                            value: "180,00€"
                        },
                        {
                            key: "Jede weitere Behandlung",
                            value: "70,00€"
                        },
                    ]
                },
                {
                    name: "Lippen",
                    title: "Permanent Make-Up",
                    childs: [
                        {
                            key: "Teilschattierung / Ombrélips",
                            value: "300,00€"
                        },
                        {
                            key: "Vollschattierung / Velvetlips",
                            value: "350,00€"
                        },
                        {
                            key: "Korrektur Lippenkranz",
                            value: "280,00€"
                        },
                        {
                            key: "Erstbehandlung + EINE Nacharbeit enthalten",
                            value: ""
                        },
                        {
                            key: "Auffrischen",
                            value: ""
                        },
                        {
                            key: "Nach 1 Jahr",
                            value: "150,00€"
                        },
                        {
                            key: "Nach 1,5 Jahren",
                            value: "180,00€"
                        },
                        {
                            key: "Jede weitere Behandlung",
                            value: "70,00€"
                        },
                    ]
                },
                {
                    name: "Gesicht",
                    childs: [
                        {
                            key: "Microneedling",
                            value: "85,00€"
                        },
                        {
                            key: "Bbglow Basistreatment",
                            value: "85,00€"
                        },
                        {
                            key: "Bbglow Specialtreatment",
                            value: "120,00€"
                        },
                        {
                            key: "Kostenlose Hautpflegeberatung",
                            value: ""
                        }
                    ]
                },
                {
                    name: "Auge",
                    title: "Permanent Make-Up",
                    childs: [
                        {
                            key: "Wimpernkranz- verdichtung oben",
                            value: ""
                        },
                        {
                            key: "Wimpernkranz- verdichtung oben & unten",
                            value: ""
                        }
                    ]
                },
                {
                    name: "Haar",
                    childs: [
                        {
                            key: "Microhair<br/>Je nach Größe und Aufwand",
                            value: "<span>ab</span> 300,00€"
                        }
                    ]
                }
            ]
        }
    }
    render() { 
        return (
            <section id="pricing" className={'section'}>
                <div className={'container'}>
                    <Masonry
                        className={'pricing-wrapper'}
                        options={this.state.masonryOptions}
                    >
                        {this.state.pricing.map(item => (
                            <PricingItem key={item.name} data={item} />
                        ))}
                    </Masonry>
                    <p><small>Vergebene Termine sind unabhängig aus welchen Gründen, mindestens 1 Werktag vorher abzusagen. Im Falle einer nicht fristgerechten Absage gilt folgendes: 1. Bei Absagen innerhalb von 24 Stunden vor dem vereinbarten Termin bleibt die Verpflichtung der Zahlung von 50% vom Behandlungspreis. 2. Erscheint der Kunde nicht zum vereinbarten Termin und sagt diesen auch nicht mindestens 24 Stunden vorher ab, sind wir berechtigt, dem Kunden den nicht abgesagten Termin gemäß § 615 BGB vollständig in Rechnung zu stellen. Ein Anspruch auf Ersatzleistung seitens des Kunden besteht nicht.</small></p>
                </div>
            </section>
        );
    }
}

class PricingItem extends React.Component {
    constructor(props) {
        super(props);
    }
    render() { 
        return (
            <div className="pricing-item">
                <div className="gallery-text rose-background">
                    <div className="text-wrapper">
                        { this.props.data.title != undefined &&
                            <span className="sub-title">{this.props.data.title}</span>
                        }
                        <h2 className="section-title">{this.props.data.name}</h2>
                        <table>
                            <tbody>
                                {this.props.data.childs.map(item => (
                                    <tr key={item.key}>
                                        <td dangerouslySetInnerHTML={{__html: item.key}}></td>
                                        <td dangerouslySetInnerHTML={{__html: item.value}}></td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }
}
 
export default Pricing;