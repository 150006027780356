import React from "react";
import AnchorLink from 'react-anchor-link-smooth-scroll';

class Menu extends React.Component {
    render() { 
        return (
            <div id="side-menu" className={this.props.isActive ? 'is-active ' : ''}>
                <div id="menu" className="menu">
                    <ul>
                        <li><AnchorLink href="#intro">Home</AnchorLink></li>
                        <li><AnchorLink href="#about">Über mich</AnchorLink></li>
                        <li><AnchorLink href="#service">Leistungen</AnchorLink></li>
                        <li><AnchorLink href="#gallery">Galerie</AnchorLink></li>
                        <li><AnchorLink href="#pricing">Preise</AnchorLink></li>
                        <li><AnchorLink href="#contact">Kontakt</AnchorLink></li>
                    </ul>
                </div>
            </div>
        );
    }
}

export default Menu;