import React from 'react';
import Menu from './Menu';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

class Intro extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            active: false,
            visible: false
        };
    };

    componentWillUpdate() {
        if( this.state.active == true )
            this.setState({active: false});
    }

    componentDidMount() {
        this.setState({visible: true});
    }

    render() {
        return (
        <header>
            <div id="logo" className={(this.state.visible ? 'visible' : '')}>
                <Link to="/">
                    <div className="shadow">
                        <svg version="1.1" id="logo_e1" x="0px" y="0px" width="50px" height="50px" viewBox="0 0 70 70" enableBackground="new 0 0 70 70">
                        <path fill="#fff" d="M41.826,64.322c-1.063,0-2.072-0.081-3.056-0.199c3.223-3.73,5.139-9.458,5.139-17.885V23.785
                        c3.355,0.212,6.189,1,8.269,2.009l1.555-5.25c-1.509-0.787-4.772-1.918-9.823-2.165V0.241h-8.875V19.08
                        C23.398,21.754,15.87,31.339,15.87,44.506c0,7.744,2.377,13.807,6.37,18.047c-2.898-0.021-5.494-0.621-7.297-1.322l-1.326,7.24
                        c2.244,0.92,6.222,1.633,9.485,1.633c3.347,0,6.464-0.45,9.215-1.507c2.538,0.741,5.282,1.124,8.178,1.124
                        c6.063,0,10.798-1.181,13.164-2.363l-1.332-5.106C49.736,63.509,45.745,64.322,41.826,64.322z M35.034,24.809v20.717
                        c0,8.451-1.85,13.003-5.195,15.221c-4.628-3.432-7.166-9.156-7.166-16.465C22.673,34.071,27.411,27.302,35.034,24.809z"></path>
                        </svg>
                    </div>
                </Link>
            </div>
            <button className={'hamburger hamburger--collapse ' + (this.props.showHamburger == "true" ? 'show ' : 'hidden ') + (this.state.active ? 'is-active ' : '') + (this.state.visible ? 'visible' : '')} onClick={() => this.setState({ active: !this.state.active })} type="button">
                <span className="hamburger-box">
                <span className="hamburger-inner"></span>
                </span>
            </button>
            <section id="intro" className={this.props.style}>
                <div className="intro-wrapper">
                    <div className="caption-wrapper clearfix">
                        <div className="caption">
                            <h1 className={'page-title ' + (this.state.visible ? 'visible' : '')}><strong>{this.props.strongTitle}</strong><br/>{this.props.title}</h1>
                        </div>
                    </div>
                    <div className="address">
                        <h2>Hasselrade 43<br/>24114 Kiel</h2>
                    </div>
                </div>
            </section>
            <Menu isActive={this.state.active} isVisible={this.state.visible} />
        </header>
        );
    }
}
 
export default Intro;