import React from "react";

class Microblading extends React.Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    render() { 
        return (
            <section id="content" className={"section"}>
                <div className={"container"}>
                    <h2>Was ist Microblading?</h2>
                    <p>Microblading ist eine permanente Härchenzeichnung für die Augenbrauen. Vor der Behandlung wird die optimale Augenbrauenform ausgemessen und aufgezeichnet. Mit einer kleinen Klinge (steril verpackte einzelne Blades) werden dann feine Hörchen in die Haut geritzt. Im Gegensatz zu dem klassische Permanent Make-up, bei dem die Farbe mit einer Nadel unter Haut gestochen wird, ist diese Methode ein wenig schmerzhafter.</p>
                    <h2>Wie lange dauert eine Behandlung?</h2>
                    <p>Eine Microblading Behandlung dauert im Durchschnitt etwa 60-90 Minuten.</p>
                    <p>Es beinhaltet die Beratung, das Vermessen und Vorzeichnen der Brauen. Wahl des Farbtons und die Pigmentierung.</p>
                    <h2>Wie lange hält Microblading?</h2>
                    <p>Im Schnitt hält Microblading  1 bis 2 Jahre, abhängig von der Beschaffenheit der Haut, dem Stoffwechsel, den UV-Strahlen und der Hautpflege.</p>
                    <h2>Ist eine Nachbehandlung nötig?</h2>
                    <p>Ja, zwischen 4 und 8 Wochen gibt es eine Nachbehandlung. Nach der Heilung können Korrekturen der Form und Dicke der Augenbrauen angepasst und evtl. kleine Änderungen durchgeführt werden. Wenn Sie sich nicht sicher mit der Farbintensität sind, verwenden wir während der ersten Behandlung die helleren Farbtöne. Nach einem Monat kann dann, wenn Sie es wünschen, eine intensivere Farbe verwendet werden.</p>
                    <h2>Ist die Nachbehandlung im Preis inbegriffen?</h2>
                    <p>Ja, eine Nachbehandlung ist inklusive</p>
                    <h2>Wie lange bleiben die Rötungen oder Schwellungen nach der Microblading Behandlung vorhanden?</h2>
                    <p>Schwellungen kommen eher selten vor.  Rötungen sind normal und klingen meist im Laufe des Tages ab.</p>
                    <h2>Für wen eignet sich Microblading?</h2>
                    <p>Diese Methode eignet sich für alle, die schön geformte aber trotzdem natürlich aussehende Augenbrauen haben möchten.</p>
                    <h2>Was sollte ich vor der Microblading Behandlung beachten?</h2>
                    <p>Zwei Tage vor der Behandlung können Sie die trockenen und abgestorbenen Hautschichten mittels eines Peelings entfernen lassen. Außerdem ist es wichtig keine Blutverdünnten Medikamente einzunehmen.</p>
                </div>
            </section>
        );
    }
}
 
export default Microblading;