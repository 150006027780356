import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import MicrobladingImage from '../assets/images/microblading.jpg';
import PermanentMakeupImage from '../assets/images/permanent-make-up.jpg';

class Service extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            service: [
                {
                    name: "Permanent Make-Up",
                    image: PermanentMakeupImage,
                    slug: "permanent-makeup"
                },
                {
                    name: "Microblading",
                    image: MicrobladingImage,
                    slug: "microblading"
                }
            ]
        }
    }
    render() { 
        return (
            <section id="service" className="section">
                <div className="container">
                    <div className="service-wrapper">
                        {this.state.service.map(item => (
                            <div key={item.name} className={'service-box'}>
                                <figure className={'service-image'}>
                                    <Link to={item.slug}>
                                        <img src={item.image} className={'img-responsive'} />
                                        <figcaption>
                                            <h4 className={'service-caption'}>{item.name}</h4>
                                        </figcaption>
                                    </Link>
                                </figure>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        );
    }
}
 
export default Service;