import React from "react";
import ReactFancyBox from 'react-fancybox';
import '../../node_modules/react-fancybox/lib/fancybox.css';

class Gallery extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            gallery: [
                {
                    thumbnail: "https://julia-cosmetics.de/assets/01-julia-cosmetics-1270x645px-196x96.jpg",
                    full: "https://julia-cosmetics.de/assets/01-julia-cosmetics-1270x645px.jpg"
                },
                {
                    thumbnail: "https://julia-cosmetics.de/assets/02-julia-cosmetics-1270x645px-196x96.jpg",
                    full: "https://julia-cosmetics.de/assets/02-julia-cosmetics-1270x645px.jpg"
                },
                {
                    thumbnail: "https://julia-cosmetics.de/assets/03-julia-cosmetics-1270x645px-196x96.jpg",
                    full: "https://julia-cosmetics.de/assets/03-julia-cosmetics-1270x645px.jpg"
                },
                {
                    thumbnail: "https://julia-cosmetics.de/assets/04-julia-cosmetics-1270x645px-196x96.jpg",
                    full: "https://julia-cosmetics.de/assets/04-julia-cosmetics-1270x645px.jpg"
                },
                {
                    thumbnail: "https://julia-cosmetics.de/assets/05-julia-cosmetics-1270x645px-196x96.jpg",
                    full: "https://julia-cosmetics.de/assets/05-julia-cosmetics-1270x645px.jpg"
                },
                {
                    thumbnail: "https://julia-cosmetics.de/assets/06-julia-cosmetics-1270x645px-196x96.jpg",
                    full: "https://julia-cosmetics.de/assets/06-julia-cosmetics-1270x645px.jpg"
                },
                {
                    thumbnail: "https://julia-cosmetics.de/assets/07-julia-cosmetics-1270x645px-196x96.jpg",
                    full: "https://julia-cosmetics.de/assets/07-julia-cosmetics-1270x645px.jpg"
                },
                {
                    thumbnail: "https://julia-cosmetics.de/assets/08-julia-cosmetics-1270x645px-196x96.jpg",
                    full: "https://julia-cosmetics.de/assets/08-julia-cosmetics-1270x645px.jpg"
                },
                {
                    thumbnail: "https://julia-cosmetics.de/assets/09-julia-cosmetics-1270x645px-196x96.jpg",
                    full: "https://julia-cosmetics.de/assets/09-julia-cosmetics-1270x645px.jpg"
                },
                {
                    thumbnail: "https://julia-cosmetics.de/assets/10-julia-cosmetics-1270x645px-196x96.jpg",
                    full: "https://julia-cosmetics.de/assets/10-julia-cosmetics-1270x645px.jpg"
                }
            ]
        }
    }
    render() { 
        return (
            <section id="gallery" className={'section'}>
                <div className={'container'}>
                    <div className={'row'}>
                        {this.state.gallery.map(item => (
                            <ReactFancyBox thumbnail={item.thumbnail} image={item.full}/>
                        ))}
                    </div>
                </div>
            </section>
        );
    }
}
 
export default Gallery;