import React from 'react';
import 'normalize.css';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import {Helmet} from "react-helmet";
import Imprint from './components/Imprint';
import Privacy from './components/Privacy';
import Intro from './components/Intro';
import About from './components/About';
import Service from './components/Service';
import Gallery from './components/Gallery';
import Pricing from './components/Pricing';
import Contact from './components/Contact';
import Footer from './components/Footer';
import Microblading from './components/Microblading';
import PermanentMakeUp from './components/PermanentMakeUp';
import './assets/css/index.scss';

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/permanent-makeup">
          <Helmet>
            <meta charSet="utf-8"/>
            <meta name="viewport" content="width=device-width, initial-scale=1.0, user-scalable=no"/>
            <title>Permanent Make-Up aus Kiel &mdash; Julia Cosmetics</title>
            <meta name="description" content="Beim Permanent Make-Up werden Farbpigmente in die oberste Hautschicht gestochen."/>
            <link rel="canonical" href="https://julia-cosmetics.de/datenschutzerklaerung/" />
            <meta property="og:locale" content="de_DE" />
            <meta property="og:type" content="website" />
            <meta property="og:title" content="Permanent Make-Up aus Kiel &mdash; Julia Cosmetics" />
            <meta property="og:description" content="Beim Permanent Make-Up werden Farbpigmente in die oberste Hautschicht gestochen." />
            <meta property="og:url" content="https://julia-cosmetics.de/datenschutzerklaerung/" />
            <meta property="og:site_name" content="Julia Cosmetics" />
            <meta property="og:image" content="https://www.julia-cosmetics.de/assets/socialmedia_wallpaper.jpg" />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:description" content="Beim Permanent Make-Up werden Farbpigmente in die oberste Hautschicht gestochen." />
            <meta name="twitter:title" content="Permanent Make-Up aus Kiel &mdash; Julia Cosmetics" />
            <meta name="twitter:image" content="https://www.julia-cosmetics.de/assets/socialmedia_wallpaper.jpg" />
          </Helmet>
          <Intro showHamburger="false" style="normal" strongTitle="Permanent" title="Make-Up aus Kiel" />
          <PermanentMakeUp />
          <Contact />
          <Footer />
        </Route>
        <Route path="/microblading">
          <Helmet>
            <meta charSet="utf-8"/>
            <meta name="viewport" content="width=device-width, initial-scale=1.0, user-scalable=no"/>
            <title>Microblading aus Kiel &mdash; Julia Cosmetics</title>
            <meta name="description" content="Microblading ist eine permanente Härchenzeichnung für die Augenbrauen."/>
            <link rel="canonical" href="https://julia-cosmetics.de/datenschutzerklaerung/" />
            <meta property="og:locale" content="de_DE" />
            <meta property="og:type" content="website" />
            <meta property="og:title" content="Microblading aus Kiel &mdash; Julia Cosmetics" />
            <meta property="og:description" content="Microblading ist eine permanente Härchenzeichnung für die Augenbrauen." />
            <meta property="og:url" content="https://julia-cosmetics.de/datenschutzerklaerung/" />
            <meta property="og:site_name" content="Julia Cosmetics" />
            <meta property="og:image" content="https://www.julia-cosmetics.de/assets/socialmedia_wallpaper.jpg" />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:description" content="Microblading ist eine permanente Härchenzeichnung für die Augenbrauen." />
            <meta name="twitter:title" content="Microblading aus Kiel &mdash; Julia Cosmetics" />
            <meta name="twitter:image" content="https://www.julia-cosmetics.de/assets/socialmedia_wallpaper.jpg" />
          </Helmet>
          <Intro showHamburger="false" style="normal" strongTitle="Microblading" title="aus Kiel" />
          <Microblading />
          <Contact />
          <Footer />
        </Route>
        <Route path="/datenschutzerklaerung">
          <Helmet>
            <meta charSet="utf-8"/>
            <meta name="viewport" content="width=device-width, initial-scale=1.0, user-scalable=no"/>
            <title>Datenschutzerklärung &mdash; Julia Cosmetics</title>
            <meta name="description" content="1. Datenschutz auf einen Blick Allgemeine Hinweise Die folgenden Hinweise geben einen einfachen Überblick darüber, was mit Ihren personenbezogenen Daten passiert, wenn Sie diese Website besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie persönlich identifiziert werden können. Ausführliche Informationen zum Thema Datenschutz entnehmen Sie unserer unter diesem Text aufgeführten Datenschutzerklärung. Datenerfassung auf dieser Website &hellip;"/>
            <link rel="canonical" href="https://julia-cosmetics.de/datenschutzerklaerung/" />
            <meta property="og:locale" content="de_DE" />
            <meta property="og:type" content="website" />
            <meta property="og:title" content="Datenschutzerklärung &mdash; Julia Cosmetics" />
            <meta property="og:description" content="1. Datenschutz auf einen Blick Allgemeine Hinweise Die folgenden Hinweise geben einen einfachen Überblick darüber, was mit Ihren personenbezogenen Daten passiert, wenn Sie diese Website besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie persönlich identifiziert werden können. Ausführliche Informationen zum Thema Datenschutz entnehmen Sie unserer unter diesem Text aufgeführten Datenschutzerklärung. Datenerfassung auf dieser Website &hellip;" />
            <meta property="og:url" content="https://julia-cosmetics.de/datenschutzerklaerung/" />
            <meta property="og:site_name" content="Julia Cosmetics" />
            <meta property="og:image" content="https://www.julia-cosmetics.de/assets/socialmedia_wallpaper.jpg" />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:description" content="1. Datenschutz auf einen Blick Allgemeine Hinweise Die folgenden Hinweise geben einen einfachen Überblick darüber, was mit Ihren personenbezogenen Daten passiert, wenn Sie diese Website besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie persönlich identifiziert werden können. Ausführliche Informationen zum Thema Datenschutz entnehmen Sie unserer unter diesem Text aufgeführten Datenschutzerklärung. Datenerfassung auf dieser Website &hellip;" />
            <meta name="twitter:title" content="Datenschutzerklärung &mdash; Julia Cosmetics" />
            <meta name="twitter:image" content="https://www.julia-cosmetics.de/assets/socialmedia_wallpaper.jpg" />
          </Helmet>
          <Privacy />
          <Footer />
        </Route>
        <Route path="/impressum">
          <Helmet>
            <meta charSet="utf-8"/>
            <meta name="viewport" content="width=device-width, initial-scale=1.0, user-scalable=no"/>
            <title>Impressum &mdash; Julia Cosmetics</title>
            <meta name="description" content="Angaben gemäß § 5 TMG Julia Hüttmann Hasselrade 43 24114 Kiel &nbsp; Hinweis: Als Kleinunternehmer im Sinne von § 19 Abs. 1 UStG wird keine Umsatzsteuer berechnet. Kontakt Telefon: 0151 28447899 E-Mail: info@julia-cosmetics.de Gestaltung &amp; technische Umsetzung Erik Ivanov Hamburger Allee 64 19063 Schwerin E-Mail: e.ivanov@themeative.com Web: https://www.themeative.com Streitschlichtung Die Europäische Kommission stellt eine Plattform zur &hellip;"/>
            <link rel="canonical" href="https://julia-cosmetics.de/impressum/" />
            <meta property="og:locale" content="de_DE" />
            <meta property="og:type" content="website" />
            <meta property="og:title" content="Impressum &mdash; Julia Cosmetics" />
            <meta property="og:description" content="Angaben gemäß § 5 TMG Julia Hüttmann Hasselrade 43 24114 Kiel &nbsp; Hinweis: Als Kleinunternehmer im Sinne von § 19 Abs. 1 UStG wird keine Umsatzsteuer berechnet. Kontakt Telefon: 0151 28447899 E-Mail: info@julia-cosmetics.de Gestaltung &amp; technische Umsetzung Erik Ivanov Hamburger Allee 64 19063 Schwerin E-Mail: e.ivanov@themeative.com Web: https://www.themeative.com Streitschlichtung Die Europäische Kommission stellt eine Plattform zur &hellip;" />
            <meta property="og:url" content="https://julia-cosmetics.de/impressum/" />
            <meta property="og:site_name" content="Julia Cosmetics" />
            <meta property="og:image" content="https://www.julia-cosmetics.de/assets/socialmedia_wallpaper.jpg" />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:description" content="Angaben gemäß § 5 TMG Julia Hüttmann Hasselrade 43 24114 Kiel &nbsp; Hinweis: Als Kleinunternehmer im Sinne von § 19 Abs. 1 UStG wird keine Umsatzsteuer berechnet. Kontakt Telefon: 0151 28447899 E-Mail: info@julia-cosmetics.de Gestaltung &amp; technische Umsetzung Erik Ivanov Hamburger Allee 64 19063 Schwerin E-Mail: e.ivanov@themeative.com Web: https://www.themeative.com Streitschlichtung Die Europäische Kommission stellt eine Plattform zur &hellip;" />
            <meta name="twitter:title" content="Impressum &mdash; Julia Cosmetics" />
            <meta name="twitter:image" content="https://www.julia-cosmetics.de/assets/socialmedia_wallpaper.jpg" />
          </Helmet>
          <Imprint />
          <Footer />
        </Route>
        <Route path="/">
          <Helmet>
            <meta charSet="utf-8"/>
            <meta name="viewport" content="width=device-width, initial-scale=1.0, user-scalable=no"/>
            <title>Zertifizierte Pigmentiererin aus Kiel – Julia Hüttmann</title>
            <meta name="description" content="Ihre zertifizierte Pigmentiererin im Bereich Microblading und Permanent Make-Up. Ich Betone Ihre natürliche Schönheit mit professionellen Mitteln!"/>
            <link rel="canonical" href="https://julia-cosmetics.de/" />
            <meta property="og:locale" content="de_DE" />
            <meta property="og:type" content="website" />
            <meta property="og:title" content="Zertifizierte Pigmentiererin aus Kiel – Julia Hüttmann" />
            <meta property="og:description" content="Ich bin zertifizierte Pigmentiererin im Bereich Microblading und Permanent Make-Up. In meinem Studio berate ich dich gerne zu Themen, wie Schönheit und Behandlungen. Besuch meine Website und erhalte einen Einblick in meine Arbeiten." />
            <meta property="og:url" content="https://julia-cosmetics.de/" />
            <meta property="og:site_name" content="Julia Cosmetics" />
            <meta property="og:image" content="https://www.julia-cosmetics.de/assets/socialmedia_wallpaper.jpg" />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:description" content="Ich bin zertifizierte Pigmentiererin im Bereich Microblading und Permanent Make-Up. In meinem Studio berate ich dich gerne zu Themen, wie Schönheit und Behandlungen. Besuch meine Website und erhalte einen Einblick in meine Arbeiten." />
            <meta name="twitter:title" content="Zertifizierte Pigmentiererin aus Kiel – Julia Hüttmann" />
            <meta name="twitter:image" content="https://www.julia-cosmetics.de/assets/socialmedia_wallpaper.jpg" />
          </Helmet>
          <Intro showHamburger="true" style="fullscreen" strongTitle="Pigmentiererin" title="aus Kiel" />
          <About />
          <Service />
          <Gallery />
          <Pricing />
          <Contact />
          <Footer />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
