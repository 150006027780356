import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

class Footer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    render() { 
        return (
        <footer id="footer" className="section">
            <div className="container">
                <div className="copyright text-center">
                    © by Julia Cosmetics - all rights reserved!
                </div>
                <div className="text-center">
                    <Link to="/impressum/">Impressum</Link>
                    <span> | </span>
                    <Link to="/datenschutzerklaerung/">Datenschutz</Link>
                </div>
            </div>
        </footer>
        );
    }
}
 
export default Footer;